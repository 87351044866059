<div *ngIf="tableData?.length !== 0" class="table-wrapper">
	<table [ngClass]="{ 'text-end': alignRight }" class="table table-striped">
		<thead>
			<tr *ngIf="preHeaders" class="pre-header">
				<th *ngFor="let header of preHeaders" [attr.colspan]="header.colspan">
					<p class="pre-header-title">{{ header.name | translate }}</p>
					<p class="pre-header-value">{{ header.value }}</p>
				</th>
			</tr>
			<tr>
				<th *ngIf="hasNotifications()" class="notification-column"></th>
        @for(header of headers; track $index) {
				  <th [class]="headers[$index].headerStyle">{{ header.name | translate }}</th>
        }
        @if (actions) {
				  <th class="actions_header">{{ "TABLE.ACTIONS" | translate }}</th>
        }
			</tr>
		</thead>
		<tfoot *ngIf="footerData?.length">
			<td *ngFor="let header of headers" [style.padding]="rowPadding">
				<p>
					{{ getFooterValueForHeader(header) }}
				</p>
			</td>
			<td *ngIf="actions">
				<p></p>
			</td>
		</tfoot>
		<tbody>
			<tr *ngFor="let item of tableData; let itemIndex = index">
				<td
					*ngIf="hasNotifications()"
					class="align-middle text-center notification-column"
				>
					<img
						class="notification-img"
						*ngIf="warnings[itemIndex]"
						src="../../../assets/icons/unread.svg"
					/>
				</td>
				<td
					*ngFor="let header of headers; let i = index"
					[ngClass]="header.classesStyle"
					[style.padding]="rowPadding"
				>
					<div *ngIf="header.linkSettings; else showOther">
						<p
							(click)="
								navigateToLink(
									header.linkSettings.routeUrlFragments,
									item[header.linkSettings.routeIdPropertyName]
								)
							"
							class="pointer"
						>
							{{ getRowValueFromHeader(header, item) }}
						</p>
					</div>

					<ng-template #showOther>
            @if (header.iconSettings && header.iconSettings.hasIcon){
              <div>
                <p>
                  @if (header.iconSettings.onClick !== undefined){
                    <img
                      [ngSrc]="header.iconSettings.getIconURL(getRowValueFromHeader(header, item))"
                      width="32"
                      height="32"
                      alt="alert"
                      (click)="header.iconSettings.onClick(itemIndex)"
                      />
                  }@else{
                  <img
                    [ngSrc]="header.iconSettings.getIconURL(getRowValueFromHeader(header, item))"
                    width="32"
                    height="32"
                    alt="alert"
                    />
                  }
                  &nbsp;{{ getRowValueFromHeader(header, item) }}
                </p>
              </div>
            }

						<p
							*ngIf="
								header.value !== 'owned' &&
								header.value !== 'productionFactor' &&
								header.value !== 'contractId' &&
								header.value !== 'status' &&
								!header.editableItem &&
								!header?.iconSettings?.hasIcon &&
								!header.progressBar
							"
						>
							{{ getRowValueFromHeader(header, item) }}
						</p>
						<p
							*ngIf="header.value === 'owned'"
							[ngClass]="item['owned'] === true ? 'bg-owned' : 'bg-rented'"
							class="text-white text-center owner"
						>
							{{
								item["owned"] === true
									? ("CROPS.OWNED" | translate)
									: ("CROPS.RENTED" | translate)
							}}
						</p>
						<div *ngIf="header.progressBar" class="progress-container">
							<app-progress
								[progressPercent]="getRowValueFromHeader(header, item)"
								[progressWidth]="'16.1rem'"
								[settings]="header.progressBar"
								(mouseenter)="visibleTooltipIndex = itemIndex"
								(mouseleave)="visibleTooltipIndex = -1"
							></app-progress>
							<div *ngIf="header.progressBar?.hasTooltip">
								<app-tooltip
									*ngIf="visibleTooltipIndex === itemIndex"
									isBottom="true"
									[textArr]="getTooltipText(itemIndex)"
								/>
							</div>
						</div>
						<!-- TODO: Refactor to depend on attributes instead-->
						<p
							*ngIf="
								header.value === 'status' &&
								getRowValueFromHeader(header, item) === 'Sold'
							"
						>
							<img src="/assets/icons/money-bag.svg" />
						</p>
						<p
							*ngIf="
								header.value === 'status' &&
								getRowValueFromHeader(header, item) === 'Stored'
							"
						>
							<img src="/assets/icons/warehouse.svg" />
						</p>
						<p
							*ngIf="
								header.value === 'contractId' &&
								getRowValueFromHeader(header, item)
							"
							[routerLink]="
								'/sales/sales-contract/' + getRowValueFromHeader(header, item)
							"
						>
							<img src="/assets/icons/view.svg" />
							<span> {{ "DELIVERIES.VIEW" | translate }}</span>
						</p>
						<ng-container *ngIf="header.editableItem">
							<div
								*ngIf="item['id'] !== itemEditableId"
								class="d-flex align-content-center"
							>
								<span class="me-4">{{
									getRowValueFromHeader(header, item)
								}}</span>
								<img
									(click)="
										$event.stopPropagation(); this.itemEditableId = item['id']
									"
									alt=""
									src="/assets/icons/pencil-fill.svg"
								/>
							</div>
							<div
								*ngIf="item['id'] === itemEditableId"
								class="d-flex align-content-center"
							>
								<input
									(click)="$event.stopPropagation()"
									(focusout)="animateIcon($event, okIcon)"
									[formControl]="editableValueControl"
									class="editable__input d-inline-block me-4"
									inputmode="numeric"
									oninput="this.value = this.value.replace(/\D+/g, '')"
									type="number"
								/>
								<button class="btn btn-white" type="button">
									<img
										#okIcon
										(click)="
											$event.stopPropagation();
											patchValueChange(
												item['id'],
												editableValueControl.value
											)
										"
										class="okIcon"
										height="20px"
										src="/assets/icons/icons-ok.svg"
										width="20px"
									/>
								</button>
								<button class="btn btn-white" type="button">
									<img
										(click)="$event.stopPropagation(); this.itemEditableId = ''"
										height="16px"
										src="/assets/icons/cancel-close.svg"
										width="16px"
									/>
								</button>
							</div>
						</ng-container>
					</ng-template>
				</td>
				<td *ngIf="actions">
					<div class="dropdown dropend">
						<button
							aria-expanded="false"
							class="btn btn-transparent btn-sm"
							data-bs-toggle="dropdown"
							id="dropdownMenuButton1"
							type="button"
						>
							<img alt="actions" ngSrc="assets/icons/actions.svg" height="14" width="34"/>
						</button>
						<ul aria-labelledby="dropdownMenuButton1" class="dropdown-menu">
							<div
								(click)="action.callback(item['id'])"
								*ngFor="let action of actions"
								class="d-flex justify-content-around"
							>
								<li
									*ngIf="action.label !== 'VIEW_PDF' || item['pdfFileName']"
									class="fs-4 btn justify-self-start"
									id="{{ action.label }}"
								>
									{{ action.label!! | translate }}
								</li>
							</div>
						</ul>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div *ngIf="tableData?.length !== 0 && showPagination" class="pagination">
	<div class="d-flex align-items-center justify-content-end ms-auto">
		<span class="me-5">{{ "TABLE.ROWS_PER_PAGE" | translate }}:</span>
		<div class="form-select-wrapper me-5">
			<select
				#teams
        (change)="onPageSizeChange($event)"
        class="form-select"
				title="pagination option"
			>
				<option
					*ngFor="let paginationValue of paginationList"
					[value]="paginationValue"
					[selected]="paginationValue === pageSizeSignal()"
				>
					{{ paginationValue }}
				</option>
			</select>
			<div class="form-select-wrapper__underline"></div>
		</div>
		<span class="me-5"
			>{{ pageNumberSignal() }} {{ "TABLE.OF" | translate }}
			{{ numberOfPages }}</span
		>

		<div class="me-5 paginate paginate__left">
			<button
				(click)="previous()"
				[disabled]="pageNumberSignal() === 1"
				class="btn"
				title="previous"
			>
				<svg
					fill="none"
					height="33"
					viewBox="0 0 32 33"
					width="32"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M19.4219 11.4199L14.8281 16.0137L19.4219 20.6074L18.0156 22.0137L12.0156 16.0137L18.0156 10.0137L19.4219 11.4199Z"
						fill="#333333"
					/>
				</svg>
			</button>
		</div>
		<div class="paginate paginate__right">
			<button
				(click)="next()"
				[disabled]="pageNumberSignal() >= numberOfPages"
				class="btn btn-border"
				title="next"
			>
				<svg
					fill="none"
					height="33"
					viewBox="0 0 32 33"
					width="32"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M13.9844 10.0137L19.9844 16.0137L13.9844 22.0137L12.5781 20.6074L17.1719 16.0137L12.5781 11.4199L13.9844 10.0137Z"
						fill="#333333"
					/>
				</svg>
			</button>
		</div>
	</div>
</div>
